import { register } from 'register-service-worker'
import { Notify } from 'quasar'
import { i18n } from 'boot/i18n'
const { t } = i18n.global

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

let dismissUpdateFoundNotification

register(process.env.SERVICE_WORKER_FILE, {
    // The registrationOptions object will be passed as the second argument
    // to ServiceWorkerContainer.register()
    // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

    // registrationOptions: { scope: './' },

    ready (/* registration */) {
    // console.log('Service worker is active.')
    },

    registered (registration) {
    // console.log('Service worker has been registered.')
    // TODO: Change to once per day (86400000) and add some randomness to avoid synchronisation of clients after a new version is released
        setInterval(() => registration.update(), 300000)
    },

    cached (/* registration */) {
    // console.log('Content has been cached for offline use.')
    },

    updatefound (registration) {
        console.debug('Service worker - new content is downloading.')

        // Ignore the event if this is our first service worker and thus not an update
        if (!registration.active) {
            return
        }

        dismissUpdateFoundNotification = Notify.create({
            type: 'ongoing',
            message: t('notifications.downloading_update'),
            onDismiss () {
                dismissUpdateFoundNotification = undefined
            }
        })
    },

    updated (registration) {
        console.log('Update available', registration)

        if (!dismissUpdateFoundNotification) {
            return
        }

        dismissUpdateFoundNotification()

        const dismissUpdateAvailableNotification = Notify.create({
            message: t('notifications.update_available'),
            caption: t('notifications.press_ok_to_install'),
            icon: 'install_mobile',
            timeout: 0,
            multiLine: false,
            actions: [
                {
                    label: 'OK',
                    color: 'white',
                    handler: () => {
                        console.log('window.location.reload(true)'); window.location.reload(true)
                    }
                },
                {
                    icon: 'close',
                    color: 'white',
                    handler: () => {
                        dismissUpdateAvailableNotification()
                    }
                }
            ]
        })
    },

    offline () {
        console.log('No internet connection found. App is running in offline mode.')
        Notify.create({
            type: 'warning',
            message: t('notifications.offline')
        })
    },

    error (err) {
        console.error('Error during service worker registration:', err)
        Notify.create({
            type: 'negative',
            message: t('notifications.service_worker_error')
        })
    }
})
